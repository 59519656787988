/*input*/
.date-range-picker {
  display: flex;
  gap: 14px;
  width: 100%;
}
.react-datepicker-popper {
  z-index: 1000;
  position: absolute;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.date-range-picker > div {
  width: 100%;
}

.custom-input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
}


.date-range-picker__input {
  background-color: #383838;
  border: 1px solid #383838;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 40px 10.5px 16px;
  border-radius: 8px;
  outline: none;
  text-align: center;
  cursor: pointer;
  width: 100% !important;
  box-sizing: border-box; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.date-range-picker__input::placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  z-index: 4;
  cursor: pointer;
}

.date-range-picker input {
  background-color: #383838;
  border: 1px solid #383838;
  color: #BFBFBF;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.date-range-picker input::placeholder {
  color: #bfbfbf;
  opacity: 1;
}

/*calendar*/
.custom-calendar {
  background-color: #383838;
  border: 1px solid #ffffff;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-decoration-skip-ink: none;
  border-radius: 12px;
  overflow: hidden;
  z-index: 10;
}

.custom-calendar .react-datepicker__current-month {
  color: #fff;
  font-weight: 700;
}

.react-datepicker__month {
  margin: 0px;
  padding: 0px 16px 16px;
  margin-top: 10px;
}

.custom-calendar .react-datepicker__day {
  color: #fff;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  margin: 0px;
  display: inline-flex;
  align-items: center; 
  justify-content: center;
  vertical-align: middle;
}

.custom-calendar .react-datepicker__day:hover {
  background-color: transparent; 
  color: #fff; 
  border: none;
  margin: 0px;
}

.custom-calendar .react-datepicker__day--selected {
  background-color: #04f1d1;
  color: #1a1a1a;
  border-radius: 100%;
  margin: 0px
}

.custom-calendar .react-datepicker__day--selected:hover {
  background-color: #04f1d1;
  color: #1a1a1a;
  border-radius: 100%;
  margin: 0px;
}

.custom-calendar .react-datepicker__header {
  background-color: #383838;
  border-bottom: none;
}

.custom-calendar .react-datepicker__day-name {
  color: #BFBFBF;
  width: 40px;
  height: 38px;
  margin: 0px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 10px;
}

.date-range-picker {
  display: flex;
}

.custom-calendar-header {
  padding: 8px 26.5px 10px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-custom-header {
    font-weight: 700;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0px;
}

.custom-calendar .react-datepicker__day--outside-month {
  visibility: hidden;
}

.container-arrows {
  display: flex;
  align-items: center;
  gap: 22px;
}

.custom-calendar .react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
overflow: hidden;
}

.custom-calendar .react-datepicker__day--disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
