* {
  box-sizing: border-box;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.custom-modal {
  background-color: #1a1a1a;
  color: white;
  max-width: 565px;
  max-height: 548px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  background-color: #1a1a1a;
  padding: 14.5px 24px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

@media (max-width: 769px) {
  .custom-header {
    border-bottom: 0.5px solid #C7C7C7;
    padding: 16px;
  }
}

.custom-col {
margin: 0px;
padding: 0px;
}

.custom-body {
  flex-grow: 1;
  padding: 16px 16px 48px 16px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .custom-body {
    max-height: calc(548px - 48px - 48px);
    padding: 24px 24px 48px;
  }
}

.custom-footer {
  display: flex !important;
  justify-content: flex-end;
  background-color: #1a1a1a;
  padding: 16px 24px;
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.custom-footer-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: 0px;
}

@media (max-width: 769px) {
  .custom-footer-actions  {
    justify-content: space-between;
    width: 100%;
  }
}

.custom-footer .custom-col-footer {
  margin: 0px;
  padding: 0px;
  width: auto;
}


@media (max-width: 768px) {
  .custom-footer {
    justify-content: space-between;
    gap: 16px; 
    padding: 16px;
  }

  .custom-footer .custom-col-footer {
    width: 100%;
  }

  .custom-footer .custom-col-footer button {
    width: 100%;
  }
}

@media (max-width: 769px) {
  .custom-footer {
    border-top: 0.5px solid #C7C7C7;
  }
}

@media (max-width: 768px) {
  .custom-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .custom-body {
    height: calc(100vh - 48px - 48px);
  }

}

.btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
}

.custom-col-auto {
margin: 0px;
padding: 0px;
}

.custom-col-footer {
  margin: 0px;
  padding: 0px;
  width: auto;
}
  