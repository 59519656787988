
  
  .registration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--Grayscale-Platinum, #e6e6e6);

  .registration_inner {
    background: var(--Maincolor-Background);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .logoIconContainer img {
    width: 100px;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
    color: var(--Aluminium)
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .input-group-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .input-container,
  .input-container-small {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .input-container-small {
    width: 100%;
  }
  
  .input-group-container input[type='text'],
  .input-group-container input[type='password'] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--Aluminium);
  }
    
  .input-container-terms {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .input-container-terms label {
    font-size: 14px;
    text-align: left;
    color: var(--Aluminium)
  }
  
  .input-container-terms input[type='checkbox'] {
    width: auto;
    margin: 0;
  }
  
  button[type='submit'] {
    padding: 10px;
    font-size: 16px;
    color: var(--PureBlack);
    background-color: var(--Maincolor-contrast-lowlight);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button[type='submit']:hover {
    background-color: var(--Maincolor-contrast);
  }
  
  @media (max-width: 480px) {
    .registration_inner {
      padding: 20px;
    }
  
    .input-group-container {
      flex-direction: column;
      gap: 10px;
    }
  }
  
}