@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");

/* General styles */
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* Logo styles */
#inbetia-logo {
  display: block;
  max-height: 20vh;
  padding-top: 40px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* width: 50%; */
  /* max-width: 70%; */
  transition: max-width 0.5s ease;
}

@media screen and (max-width: 800px) {
  #inbetia-logo {
    max-width: 40%;
  }
}

/* Divider styles */
#divider-login {
  background: rgba(0, 255, 220, 0.81);
  height: 100px;
  max-width: 200px;
  border: none;
}

/* Login title styles */
#loginTitle {
  color: var(--Grayscale-Platinum, #e6e6e6);
  text-align: center;
  font-family: Roboto;
  font-size: 35px;
  font-weight: 400;
  line-height: 52px;
  margin-bottom: 30px;
  margin: 0;
}

@media screen and (max-width: 800px) {
  #loginTitle {
    color: var(--Grayscale-Platinum, #e6e6e6);
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 400;
    line-height: 52px;
    margin-bottom: 30px;
    margin: 0;
  }
}

/* Login inputs container */
.login-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 12px;
    color: red;
    padding-left: 10px;
  }
}

.inputContainer {
  display: flex;
  height: auto;
  max-height: 500px;
  width: 600px;
  padding: 20px 30px 20px 21px;
  align-items: center;
  gap: 10px;
  margin: 34px;
  border-radius: 15px;
  background: var(--Grayscale-Platinum, #e6e6e6);
}

/* Media query for smaller screen sizes */
@media screen and (max-width: 800px) {
  .inputContainer {
    width: 400px;
  }
}
/* Media query for smaller screen sizes */
@media screen and (max-width: 500px) {
  .inputContainer {
    height: auto; /* Adjust height for mobile */
    max-height: 0px; /* Adjust max-height for mobile */
    margin: 25px;
    max-width: 800px;
    width: 260px;
  }
}

/* Login input styles */
.login-inputs input[name="username"],
.login-inputs input[name="password"] {
  display: flex;
  width: 100%; /* Set width to 100% */
  background: transparent;
  border: none;
  outline: none;
  color: var(--Maincolor-Background);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  padding: 0; /* Remove any padding */
  margin: 0; /* Remove any margin */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  max-width: 90%; /* Set maximum width to 100% */
}

/* Login button styles */
#login-button {
  display: flex;
  width: 200px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border-width: 0px;
  background: var(--Maincolor-contrast-lowlight);
  color: var(--PureBlack);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 550;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.8);
  transition: 0.2s ease;
}

#login-button:hover {
  background: var(--Maincolor-contrast);
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.8);
}

#login-button:focus {
  background: var(--Maincolor-contrast);
  cursor: pointer;
  box-shadow: none;
}

#login-button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 30px;
  width: 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Footer styles */
#loginFooter {
  background-color: #333;
  color: #e6e6e6;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  border-radius: 40px 40px 0px 0px;
}

.social-icons {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#emailLogo,
#telegramLogo {
  scale: 120%;
  filter: invert(92%) sepia(9%) saturate(0%) hue-rotate(0deg) brightness(92%)
    contrast(92%);
  margin-inline: 10px;
}

/* Adjust footer styles for smaller screens */
@media screen and (max-width: 800px) {
  #loginFooter {
    scale: 100%;
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
  #loginFooter {
    font-size: 12px;
    scale: 100%;
  }
}
