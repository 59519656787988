/* Global Toast Container Styling */
.Toastify__toast-container {
  font-family: Arial, sans-serif;
}

/* Success Toast */
.Toastify__toast--success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Toastify__toast--success .Toastify__close-button {
  color: #155724;
}

/* Error Toast */
.Toastify__toast--error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.Toastify__toast--error .Toastify__close-button {
  color: #721c24;
}

/* Warning Toast */
.Toastify__toast--warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.Toastify__toast--warning .Toastify__close-button {
  color: #856404;
}

/* Info Toast */
.Toastify__toast--info {
  background-color: #d1ecf1;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

.Toastify__toast--info .Toastify__close-button {
  color: #0c5460;
}

/* Close Button Hover */
.Toastify__close-button:hover {
  opacity: 0.8;
}
